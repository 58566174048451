<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Penggajian</b> yang ada di Klinik IONA
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/payrolls/add')"
        >Tambah</b-button
      >
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row align-items-center justify-content-start mt-n3">
              <div class="col-md-9">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <template #prepend>
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                      <b-button squared @click="generalFilter" variant="success"
                        >Cari</b-button
                      >
                    </template>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Tanggal Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Tanggal Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <template #prepend>
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                          <b-button
                            squared
                            @click="generalFilter"
                            variant="success"
                            >Cari</b-button
                          >
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.employee_name"
                    placeholder="Cari Nama Karyawan"
                    @keyup="generalFilter"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <div>

              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                :style="'white-space: nowrap'"
                responsive
              >

                <template #cell(no)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(employee_name)="data">
                  {{ data.item.employee_name + " ("+ data.item.employee_number_id +")" }}
                </template>

                <template #cell(remuneration_name)="data">
                  <div
                    v-for="(name, index) in data.item.remuneration_type_name"
                    :key="index"
                  >
                    <li>
                      {{
                        data.item.remuneration_type_name[index] +
                        " (" +
                        data.item.quantity[index] +
                        " X " +
                        parseInt(
                          data.item.remuneration_ammount[index]
                        ).toLocaleString("id-ID") +
                        ")"
                      }}
                    </li>
                  </div>
                </template>

                <template #cell(ammount)="data">
                  {{
                    "Rp " + parseInt(data.item.ammount).toLocaleString("id-ID")
                  }}
                </template>

                <template #cell(payment_method)="data">
                  {{ data.item.payment_name }}
                </template>

                <template #cell(paid_date)="data">
                  {{ data.item.display_paid_date }}
                </template>

                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    @click="
                      $router.push({ path: '/payrolls/detail/' + data.item.id })
                    "
                    v-b-tooltip.hover
                    title="Detail"
                    placement="bottom"
                  >
                    <i class="fas fa-eye px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    @click="
                      $router.push({ path: '/payrolls/edit/' + data.item.id })
                    "
                    v-b-tooltip.hover
                    title="Ubah"
                    placement="bottom"
                    v-if="manipulateBtn == true"
                  >
                    <i class="fas fa-edit px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    placement="bottom"
                    v-if="manipulateBtn == true"
                    @click="deleteData(data.item.id)"
                  >
                    <i class="fas fa-trash px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="btn-warning"
                    v-b-tooltip.hover
                    title="Cetak Slip Gaji"
                    placement="bottom"
                    @click="
                      $router.push({ path: '/payrolls/print/' + data.item.id })
                    "
                  >
                    <i class="fas fa-print px-0"></i>
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "no",
          label: "No",
          sortable: true,
        },
        {
          key: "employee_name",
          label: "Nama Karyawan",
          sortable: true,
        },
        {
          key: "remuneration_name",
          label: "Remunerasi (qty X Jml)",
          sortable: true,
        },
        {
          key: "ammount",
          label: "Jumlah(Rp)",
          sortable: true,
        },
        {
          key: "payment_method",
          label: "Metode Pembayaran",
          sortable: true,
        },
        {
          key: "paid_date",
          label: "Tanggal Dibayar",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Other
      dataLoaded: false,
      manipulateBtn: false,
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,

      filter: {
        employee_name: "",
        start_date: "",
        end_date: "",
      },
    };
  },

  methods: {
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.list();
    },

    async list() {
      let filterParams = `&employee_name=${this.filter.employee_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "payrolls",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
      // this.items = await module.list('payrolls')
      console.log(this.items);
      this.dataLoaded = true;
    },

    resetFilter() {
      this.filter.employee_name = "";
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.list();
    },

    generalFilter() {
      this.list();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("payrolls/" + id);
      // If Deleted
      if (result) {
        this.list();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");

        if (access_right[a] == "7004") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Penggajian" }]);
    // Get Data
    this.list();
    this.setActiveMenu();
  },
};
</script>
